.__index___bg {
  background: #f0f5f8;
  height: 100vh; }

.__index___abnormal-show-box {
  padding-top: 231px; }
  .__index___abnormal-show-box .__index___left-show {
    width: 500px;
    float: left;
    margin: 0 67px 0 140px; }
  .__index___abnormal-show-box .__index___right-show {
    width: 300px;
    float: left;
    margin-top: 86px; }
    .__index___abnormal-show-box .__index___right-show .__index___title {
      font-size: 72px;
      color: #666666;
      margin-bottom: 15px; }
    .__index___abnormal-show-box .__index___right-show .__index___des {
      font-size: 24px;
      line-height: 24px;
      color: #999999;
      margin-bottom: 19px; }

.__index___exception403-box .__index___img-box {
  width: 224px;
  height: 223px;
  margin: 0 auto; }
  .__index___exception403-box .__index___img-box .__index___img403 {
    width: 100%;
    height: 100%;
    background: url(/static/media/403.32cfc204.png) 100% 100%; }

.__index___exception404-box .__index___img-box {
  width: 304px;
  height: 200px;
  margin: 0 auto; }
  .__index___exception404-box .__index___img-box .__index___img404 {
    width: 100%;
    height: 100%;
    background: url(/static/media/404.db0910f0.png) 100% 100%; }

.__index___exception500-box .__index___img-box,
.__index___exception-client-error-box .__index___img-box {
  width: 471px;
  height: 216px;
  margin-top: 87px; }
  .__index___exception500-box .__index___img-box .__index___img500,
  .__index___exception-client-error-box .__index___img-box .__index___img500 {
    width: 100%;
    height: 100%;
    background: url("https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg");
    background-size: cover; }

.__index___exception500-box .__index___mg,
.__index___exception-client-error-box .__index___mg {
  margin: 0 94px 0 140px; }
