.__user-layout___container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5; }

.__user-layout___content {
  padding: 32px 0;
  -ms-flex: 1;
      flex: 1; }

@media (min-width: 768px) {
  .__user-layout___container {
    background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%; }
  .__user-layout___content {
    padding: 112px 0 24px 0; } }

.__user-layout___top {
  text-align: center; }

.__user-layout___header {
  height: 44px;
  line-height: 44px; }
  .__user-layout___header a {
    text-decoration: none; }

.__user-layout___logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px; }

.__user-layout___title {
  font-size: 33px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px; }

.__user-layout___desc {
  margin-top: 12px;
  margin-bottom: 40px; }
