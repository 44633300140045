.__header-nav-layout___header-nav-layout .__header-nav-layout___header_logo {
  height: 26px;
  vertical-align: middle; }

.__header-nav-layout___header-nav-layout .__header-nav-layout___header-nav-layout-content {
  min-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #f0f2f5; }

.__header-nav-layout___header-nav-layout .__header-nav-layout___copyright {
  font-size: 13px;
  color: #bdc2c7;
  min-width: 1200px; }

.__header-nav-layout___header-nav-layout .__header-nav-layout___logout_icon:hover {
  color: #ff4d4f; }

.__header-nav-layout___header-nav-layout .ant-layout {
  min-width: 1200px; }
  .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header {
    background: #fff;
    height: auto; }
    .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header {
      width: 1200px;
      margin: 0 auto;
      line-height: 40px; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .ant-menu-horizontal {
        display: inline-block;
        line-height: 40px;
        border: none; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .ant-menu-item {
        border: none; }
        .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .ant-menu-item span {
          font-size: 14px; }
        .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .ant-menu-item:hover {
          border: none; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .ant-menu-item-selected {
        border: none; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .header_more {
        color: rgba(0, 0, 0, 0.65);
        padding: 0 10px; }
        .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header .header_more:hover {
          cursor: pointer;
          color: #1890ff; }
    .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu {
      background-color: #1c2a75;
      border-bottom: 1px solid #1c2a75; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu {
        margin: 0 auto;
        width: 1200px;
        line-height: 40px;
        background-color: #1c2a75;
        color: #DDD; }
        .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu .ant-menu-item {
          height: 40px;
          border-bottom: 3px solid transparent; }
          .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu .ant-menu-item:hover {
            color: #ffffff;
            border-color: transparent; }
        .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu .ant-menu-item-selected {
          color: #ffffff;
          border-bottom: 3px solid #ffffff; }
          .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu .ant-menu-item-selected:hover {
            border-color: #ffffff; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu-horizontal {
        border: none; }
      .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu-horizontal .ant-menu-item, .__header-nav-layout___header-nav-layout .ant-layout .ant-layout-header #header_menu .ant-menu-horizontal .ant-menu-submenu {
        margin-top: -2px; }
